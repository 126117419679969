export const useSocialMedia = async () => {
  const { currentCenterProfile } = await useCurrentCenter()

  const possiblePlatforms = [
    'mastodon',
    'facebook',
    'instagram',
    'tiktok',
    'youtube',
    'x',
  ]

  const getPlatformIconClass = (platform: string) => {
    if (platform == 'mastodon') {
      return 'mdi:mastodon'
    }
    const name = (platform == 'x' ? 'twitter' : platform).toLowerCase()
    return `ph:${name}-logo-fill`
  }

  const platforms = computed(() => {
    return possiblePlatforms
      .map((name) => {
        const key = `${name}Url` as keyof VolunteerCenterProfile
        const url = currentCenterProfile.value[key] as string | undefined
        return url ? { name, url } : null
      })
      .filter(Boolean)
  })

  return {
    possiblePlatforms,
    getPlatformIconClass,
    platforms,
  }
}
